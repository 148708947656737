<template>
  <div class="home_warp">
    <!-- 顶部导航 -->
    <!-- <titleNav title="职业技能培训项目" :left-arrow="true" left-text="" /> -->
    <div class="navBar">
      <van-nav-bar
        style="background: #0097ff"
        z-index="9999"
        title="职业技能培训项目"
        :left-arrow="true"
        @click-left="onClickLeft"
      />
    </div>
    <!-- 内容 -->
    <!-- <div class="fields">
      <van-field
        v-model="name"
        center
        clearable
        placeholder="请输入项目名称查询"
        left-icon="search"
      >
        <template #button>
          <button class="btns">搜索</button>
        </template>
      </van-field>
    </div> -->
    <div style="margin-top: 1.2667rem">
      <div
        class="lists"
        v-for="(item, index) in list"
        :key="index"
        @click="projec(item)"
      >
        <div class="images">
          <img :src="item.titlepic" alt="" />
        </div>
        <div class="sizes">
          <div class="tit_p">{{ item.goodsname }}</div>
          <!-- <button class="grade_p">高级 (三级)</button> -->
          <div class="com_p">持续报名中，点击查看申报需要具备的条件</div>
        </div>
      </div>
    </div>

    <div class="zwgd">暂无更多</div>
    <van-dialog
      class="dialog"
      v-model="show"
      show-cancel-button
      confirmButtonColor="#368CE2"
      @confirm="confirm"
    >
      <div class="com_d">
        <div>具备以下条件之一者可申报【{{ select.goodsname }}】</div>
        <div class="radio_d">
          <van-radio-group v-model="radio">
            <van-radio
              v-for="(item, index) in select.conditon"
              :key="index"
              class="ra_d"
              :name="index"
              >{{ item.condition }}</van-radio
            >
          </van-radio-group>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
// import floatBtn from "@/components/floatBtn.vue";
import {
  Button,
  Dialog,
  Icon,
  Toast,
  Field,
  RadioGroup,
  Radio,
  NavBar,
} from "vant";
import {
  getskillgoods,
  getopenid,
  getskillgoodscondition,
} from "@/network/index.js";
import login from "@/assets/js/code";
// const pushHistory = () => {
//   let state = {
//     title: 'myCenter',
//     url: '__SELF__'
//   }
//   window.history.pushState(state, state.title, state.url)
// }
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      name: "",
      list: [],
      show: false,
      select: {},
      radio: "a",
      codeGet: "",
    };
  },
  created() {
    window.scrollTo(0, 0);
    // if (
    //   sessionStorage.getItem("addse") != 1 &&
    //   sessionStorage.getItem("addse") != 2
    // ) {
    //   this.getCode();
    // }
  },
  mounted() {
    //   if (this.$route.query.code != undefined) {
    //     this.getinfor();
    //   }
    this.getlist();
    //   if (window.history && window.history.pushState) {
    // 	// 往历史记录里面添加一条新的当前页面的url
    // 	history.pushState(null, null, document.URL);
    // 	// 给 popstate 绑定一个方法用来监听页面返回
    // 	window.addEventListener('popstate', this.back, false); //false阻止默认事件
    // }
    //  pushHistory()
    // window.addEventListener('popstate', function (e) {
    //   window.WeixinJSBridge.call('closeWindow')
    // }, false)
  },
  //   destroyed() {
  // 	window.removeEventListener('popstate', this.backe, false); //false阻止默认事件
  // },
  methods: {
    back() {
      console.log("监听到了");
      this.$router.go(-2);
    },
    onClickLeft(e) {
      sessionStorage.setItem("addse", 2);
      this.$router.push("/index"); //返回上一层
    },
    getCode() {
      // 非静默授权，第一次有弹框
      sessionStorage.setItem("addse", 1);
      const code = this.getUrlParam("code"); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      console.log("code", code);
      const local = window.location.href;
      console.log(code);
      if (code == null || code === "") {
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc2bd458948e845a0&redirect_uri=" +
          "http://dingzhou.sjzydrj.net/lhjy/projects" +
          "&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
      } else {
        //this.getOpenId(code) //把code传给后台获取用户信息
        console.log(code, 1);
      }
    },
    getUrlParam: function (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    getinfor() {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
        code: this.$route.query.code,
      };
      getopenid(data).then((res) => {});
    },
    // 证书列表
    getlist() {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
      };
      getskillgoodscondition(data).then((res) => {
        this.list = res.data.data;
      });
      // let userid = sessionStorage.getItem("id");
      // let userAll=sessionStorage.getItem("userAll");
      // let data = {
      //   userid,
      //   code: userAll,
      // };
      // getskillgoods(data).then((res) => {
      //   console.log(res);
      // this.list=res.data.data
      // });
    },
    projec(e) {
      this.select = e;
      this.show = true;
    },
    // 确认按钮
    confirm() {
      this.show = true;
      if (this.radio == "a") {
        Toast("请选择申报条件");
      } else {
        this.$router.push(
          "/signup?goodsid=" +
            this.select.goodsid +
            "&pid=" +
            this.select.conditon[this.radio].id
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.navBar {
  height: 1.22667rem;
  font-size: 30px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  .van-nav-bar__text {
    color: rgb(255, 255, 255);
  }
  .van-icon {
    color: rgb(255, 255, 255);
  }
}
.navBar .van-ellipsis {
  color: rgb(255, 255, 255);
}
.fields {
  margin-top: 1.2667rem;
  padding: 0 10px;
}
.home_warp {
  height: 100%;
  background: #ffffff;
  background-color: #ffffff;
  //   position: relative;

  .lists {
    padding: 0 10px;
    padding-top: 20px;

    display: flex;
    justify-content: space-between;
    // margin-top: 20px;

    .images {
      width: 25%;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .sizes {
      width: 71%;
      height: 80px;
      position: relative;
      .tit_p {
        font-size: 16px;
        font-weight: 700;
        line-height: 30px;
      }
      .grade_p {
        display: block;
        background-color: #1989f8;
        color: #fff;
        font-size: 14px;
        padding: 3px 5px;
        border-radius: 3px;
        margin-bottom: 5px;
        border: 0;
      }
      .com_p {
        //   position: absolute;
        //   bottom: 0;
        font-size: 13px;
        color: #1989f9;
      }
    }
  }
  .zwgd {
    text-align: center;
    font-size: 14px;
    color: rgb(83, 83, 83);
    padding: 20px 0;
  }
  .dialog {
    .com_d {
      padding: 20px;
      font-size: 14px;
      color: #171717;
      .radio_d {
        margin-top: 20px;
        .ra_d {
          margin-top: 20px;
        }
      }
    }
  }
}
.btns {
  background-color: #1989f8;
  color: #fff;
  border-radius: 5px;
  border: 0;
  font-size: 16px;
  padding: 0px 20px;
  height: 35px;
}
.van-cell {
  padding: 0.26667rem 0rem;
  padding-bottom: 0;
}
</style>
